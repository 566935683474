import { useMediaQuery } from "@react-hook/media-query";
import Link from "next/link";
import React from "react";
import styled from "styled-components";
import { Solstead } from "../../../solsteads-api";

import { BIOME_ICON_MAP } from "../../constants";

import {
  ArrowContainer,
  ArrowBody,
  ArrowTextContainer,
  ArrowLabel,
  ArrowAddress,
  ArrowIcon,
  ArrowRoundedEnd,
  ArrowContents,
} from "./NeighborArrowCommon";

const LeftArrowRoundedEnd = styled(ArrowRoundedEnd)`
  border-radius: 0 50% 50% 0;
`;

const LeftArrowPointyEnd = styled.div`
  background-image: url("/images/img_left_arrow_tip.svg");
  background-size: contain;
  background-repeat: no-repeat;
  height: 80px;
  width: 42px;
  @media only screen and (max-width: 960px) {
    height: 70px;
    width: 37px;
  }
`;

const LeftArrowContents = styled(ArrowContents)`
  justify-content: space-between;
  left: 50px;
  @media only screen and (max-width: 960px) {
    left: 40px;
  }
`;

type LeftNeighborArrowProps = {
  solstead: Partial<Solstead>;
};

export const LeftNeighborArrow = ({
  solstead,
}: LeftNeighborArrowProps): JSX.Element => {
  const isSmallScreen = useMediaQuery("only screen and (max-width: 960px)");
  const iconSize = isSmallScreen ? "60" : "66";

  const linkURL = `/${solstead.id}`;

  return (
    <Link href={linkURL} passHref>
      <ArrowContainer>
        <LeftArrowPointyEnd />
        <ArrowBody />
        <LeftArrowRoundedEnd />
        <LeftArrowContents>
          <ArrowTextContainer>
            <ArrowLabel>NEIGHBOR ON THE LEFT</ArrowLabel>
            <ArrowAddress>{solstead.name}</ArrowAddress>
          </ArrowTextContainer>
          <ArrowIcon
            src={BIOME_ICON_MAP[solstead.biome.id]}
            height={iconSize}
            width={iconSize}
            alt={solstead.biome.id}
          />
        </LeftArrowContents>
      </ArrowContainer>
    </Link>
  );
};
