import Image from "next/image";
import styled from "styled-components";

export const ArrowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  position: relative;
  :hover {
    cursor: pointer;
  }
`;

export const ArrowBody = styled.div`
  background-color: #175b56;
  height: 80px;
  width: 350px;

  @media only screen and (max-width: 1150px) {
    width: 300px;
  }

  @media only screen and (max-width: 960px) {
    height: 70px;
    width: 240px;
  }
`;

export const ArrowTextContainer = styled.div`
  color: #efe4e1;
  display: flex;
  flex-direction: column;
`;

export const ArrowLabel = styled.div`
  font-family: abodeFont;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  @media only screen and (max-width: 960px) {
    font-size: 1rem;
  }
`;

export const ArrowAddress = styled.div`
  font-family: juicyFont;
  font-size: 1.5rem;
  @media only screen and (max-width: 960px) {
    font-size: 1rem;
  }
`;

export const ArrowIcon = styled(Image)`
  height: 66px;
  width: 66px;
  @media only screen and (max-width: 960px) {
    height: 60px;
    width: 60px;
  }
`;

export const ArrowRoundedEnd = styled.div`
  background-color: #175b56;
  height: 80px;
  width: 80px;
  @media only screen and (max-width: 960px) {
    height: 70px;
    width: 70px;
  }
`;

export const ArrowContents = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 413px;
  position: absolute;

  @media only screen and (max-width: 1150px) {
    width: 363px;
  }

  @media only screen and (max-width: 960px) {
    width: 300px;
  }
`;
