import React from "react";
import styled from "styled-components";
import { GetSolsteadResponse, Solstead } from "../../../../solsteads-api";
import { useGetWalletTwitterHandle } from "../../../hooks";
import { Address } from "../../../types";

import { OwnerPill } from "../../common/OwnerPill";

const GalleryHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 960px) {
    flex-direction: column;
    margin-bottom: 2rem;
  }
`;

const GalleryHeaderTitle = styled.h2`
  color: #0f1954;
  font-family: juicyFont;
  font-size: 2rem;
  margin-right: 3rem;
  @media only screen and (max-width: 960px) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;

type GalleryHeaderProps = {
  solstead: Solstead;
};

export const GalleryHeader = ({ solstead }: GalleryHeaderProps) => {
  const isGuestPage = /^[1-9][0]*-\d{1,4}$/.test(solstead.id);

  return (
    <GalleryHeaderContainer>
      <GalleryHeaderTitle>
        {isGuestPage ? "Collection" : "Current Residents"}
      </GalleryHeaderTitle>
      <OwnerPill solstead={solstead} />
    </GalleryHeaderContainer>
  );
};
