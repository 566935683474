// src/components/address-page/AddressImage.tsx

import Image from "next/image";
import React from "react";
import { Solstead } from "../../../solsteads-api";
import { Hidden } from "@mui/material";
import cloudflareLoader from "../../imageLoader";

type AddressImageProps = {
  solstead: Solstead;
};

export const AddressImage = ({ solstead }: AddressImageProps): JSX.Element => {
  return (
    <>
      <Hidden mdUp implementation="css">
        <Image
          loader={cloudflareLoader}
          src={solstead.image}
          alt={`Image of ${solstead.name}`}
          height="500"
          width="500"
        />
      </Hidden>
      <Hidden mdDown implementation="css">
        <Image
          loader={cloudflareLoader}
          src={solstead.bannerImage ?? solstead.image}
          alt={`Image of ${solstead.name}`}
          height={solstead.bannerImage ? "1000" : "500"}
          width={solstead.bannerImage ? "3000" : "500"}
        />
      </Hidden>
    </>
  );
};
