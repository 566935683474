import React from "react";
import styled from "styled-components";
import { GalleryHeader } from "./GalleryHeader";
import { GalleryWallLoader } from "./GalleryWallLoader";
import { ForSaleSection } from "./ForSaleSection";
import { MARKETPLACE_WALLETS } from "../../../constants";
import { Solstead } from "../../../../solsteads-api";

const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

type GalleryProps = {
  solstead: Solstead;
};

export const Gallery = ({ solstead }: GalleryProps) => {
  if (!solstead.owner) {
    return <></>;
  }

  const isForSale = MARKETPLACE_WALLETS.includes(solstead.owner?.address);

  if (isForSale) {
    return (
      <ForSaleSection
        walletAddress={solstead.owner?.address}
        tokenAddress={solstead.token?.address}
      />
    );
  }

  return (
    <GalleryContainer>
      <GalleryHeader solstead={solstead} />
      <GalleryWallLoader solstead={solstead}></GalleryWallLoader>
    </GalleryContainer>
  );
};
