import styled from "styled-components";
import { Solstead } from "../../../../solsteads-api";
import { GalleryWall } from "./GalleryWall";

const GalleryWallContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
  @media only screen and (max-width: 960px) {
    justify-content: center;
  }
`;

type GalleryWallProps = {
  solstead: Solstead;
};

export const GalleryWallLoader = ({ solstead }: GalleryWallProps) => {
  return (
    <GalleryWallContainer>
      <GalleryWall solstead={solstead} />
    </GalleryWallContainer>
  );
};
