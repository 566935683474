import { useMediaQuery } from "@react-hook/media-query";
import Link from "next/link";
import React from "react";
import styled from "styled-components";
import { Solstead } from "../../../solsteads-api";

import { BIOME_ICON_MAP } from "../../constants";
import { Address } from "../../types";

import {
  ArrowContainer,
  ArrowBody,
  ArrowTextContainer,
  ArrowLabel,
  ArrowAddress,
  ArrowIcon,
  ArrowRoundedEnd,
  ArrowContents,
} from "./NeighborArrowCommon";

const RightArrowTextContainer = styled(ArrowTextContainer)`
  margin-left: 0.5rem;
`;

const RightArrowRoundedEnd = styled(ArrowRoundedEnd)`
  border-radius: 50% 0 0 50%;
`;

const RightArrowPointyEnd = styled.div`
  background-image: url("/images/img_right_arrow_tip.svg");
  background-size: contain;
  background-repeat: no-repeat;
  height: 80px;
  width: 42px;
  @media only screen and (max-width: 960px) {
    height: 70px;
    width: 35px;
  }
`;

const RightArrowContents = styled(ArrowContents)`
  left: 7px;
  @media only screen and (max-width: 960px) {
    left: 5px;
  }
`;

type RightNeighborArrowProps = {
  solstead: Partial<Solstead>;
};

export const RightNeighborArrow = ({
  solstead,
}: RightNeighborArrowProps): JSX.Element => {
  const isSmallScreen = useMediaQuery("only screen and (max-width: 960px)");
  const iconSize = isSmallScreen ? "60" : "66";

  const linkURL = `/${solstead.id}`;

  return (
    <Link href={linkURL} passHref>
      <ArrowContainer>
        <RightArrowRoundedEnd />
        <ArrowBody />
        <RightArrowPointyEnd />
        <RightArrowContents>
          <ArrowIcon
            src={BIOME_ICON_MAP[solstead.biome.id]}
            height={iconSize}
            width={iconSize}
          />
          <RightArrowTextContainer>
            <ArrowLabel>NEIGHBOR ON THE RIGHT</ArrowLabel>
            <ArrowAddress>{solstead.name}</ArrowAddress>
          </RightArrowTextContainer>
        </RightArrowContents>
      </ArrowContainer>
    </Link>
  );
};
