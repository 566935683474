import React from "react";
import Image from "next/image";
import styled from "styled-components";
import UserIcon from "../../../public/images/icon_user.svg";
import TwitterIcon from "../../../public/images/icon_twitter.svg";
import { shortenPublicKey } from "../../utils";
import { Menu, MenuItem } from "@mui/material";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Solstead } from "../../../solsteads-api";
import cloudflareLoader from "../../imageLoader";

const StyledMenuItem = styled(MenuItem)`
  font-family: abodeFont !important;
  font-size: 1rem !important;
  color: ${(props) => props.theme.color.blue} !important;
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
`;

const OwnerPillAndMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MenuArrowContainer = styled.div`
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const MenuArrow = styled.div`
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 9px solid #0f1954;
  transition: transform 0.25s;
`;

const OwnerPillContainer = styled.div`
  align-items: center;
  display: flex;
  color: #0f1954;
  border: 2px solid #0f1954;
  border-radius: 10px;
  font-family: abodeFontBold;
  padding: 0.6rem 1rem;
  :hover {
    cursor: pointer;
  }
`;

const OwnerPillText = styled.span`
  height: 20px;
  margin-left: 10px;
`;

type OwnerPillProps = {
  showMenu?: boolean;
  solstead: Solstead;
};

const CDN_URL = process.env.NEXT_PUBLIC_SOLSTEADS_CDN;

export function OwnerPill({ showMenu = true, solstead }: OwnerPillProps) {
  const explorerLink = `https://explorer.solana.com/address/${solstead.owner?.address}/tokens`;
  const twitterLink = `https://twitter.com/${solstead.owner.twitterHandle}`;
  const walletDisplayText = solstead.owner?.address
    ? shortenPublicKey(solstead.owner?.address)
    : "";
  const twitterDisplayText = solstead.owner?.twitterHandle
    ? `@${solstead.owner.twitterHandle}`
    : "";

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <OwnerPillAndMenuContainer>
      <a
        href={solstead.owner?.twitterHandle ? twitterLink : explorerLink}
        target="_blank"
        rel="noreferrer"
      >
        <OwnerPillContainer>
          <Image
            loader={cloudflareLoader}
            src={solstead.owner?.twitterHandle ? TwitterIcon : UserIcon}
            height="20"
            width="20"
            alt=""
          />
          <OwnerPillText>OWNER:</OwnerPillText>
          <OwnerPillText>
            {solstead.owner?.twitterHandle
              ? twitterDisplayText
              : walletDisplayText}
          </OwnerPillText>
        </OwnerPillContainer>
      </a>
      {showMenu && (
        <>
          <MenuArrowContainer
            id="owner-menu-trigger"
            aria-controls="owner-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MenuArrow style={{ transform: anchorEl ? "rotate(90deg)" : "" }} />
          </MenuArrowContainer>
          <Menu
            id="owner-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "owner-menu-trigger",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <StyledMenuItem
              onClick={() => {
                navigator.clipboard.writeText(solstead.owner.address);
                handleClose();
              }}
            >
              <AccountBalanceWalletOutlinedIcon fontSize="small" />
              &nbsp;Copy wallet address
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://solsteads.com/${solstead.id}`
                );
                handleClose();
              }}
            >
              <ContentCopyOutlinedIcon fontSize="small" />
              &nbsp;Copy house link
            </StyledMenuItem>
            <StyledMenuItem onClick={handleClose}>
              <FileDownloadOutlinedIcon fontSize="small" />
              <a href={solstead.bannerImage} target="_blank" rel="noreferrer">
                &nbsp;Download banner image
              </a>
            </StyledMenuItem>
          </Menu>
        </>
      )}
    </OwnerPillAndMenuContainer>
  );
}
