import React from "react";
import Image from "next/image";
import styled from "styled-components";
import UserIcon from "../../../public/images/icon_user.svg";
import TwitterIcon from "../../../public/images/icon_twitter.svg";
import { shortenPublicKey } from "../../utils";

const OwnerPillContainer = styled.div`
  align-items: center;
  display: flex;
  color: #0f1954;
  border: 2px solid #0f1954;
  border-radius: 10px;
  font-family: abodeFontBold;
  padding: 0.6rem 1rem;
  :hover {
    cursor: pointer;
  }
`;

const OwnerPillText = styled.span`
  height: 20px;
`;

type OwnerPillProps = {
  icon?: string;
  link?: string;
  content?: React.ReactFragment;
};

export function TextPill({ icon, link, content }: OwnerPillProps) {
  return (
    <a href={link} target="_blank" rel="noreferrer">
      <OwnerPillContainer>
        <OwnerPillText>{content}</OwnerPillText>
      </OwnerPillContainer>
    </a>
  );
}
