import { useRouter } from "next/router";
import React, { useCallback, useEffect } from "react";
import styled from "styled-components";

import { Background, PageContainer } from "../../components/layout";
import {
  AddressImage,
  Gallery,
  Neighbors,
} from "../../components/address-page";
import { Menu } from "../../components/common";
import { getSolsteadConstants } from "../../constants";
import { STREETS_MAP } from "../../constants/streets";
import { useGetSolstead } from "../../hooks/useGetSolstead";
import { TwitterCardMetaTags } from "../../components/TwitterCardMeta";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabUnstyled from "@mui/base/TabUnstyled";
import { Typography } from "../../components/material";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { Roster } from "../../components/address-page/roster/Roster";
import { Housemates } from "./components/Housemates";
import { Guest, Solstead } from "../../../solsteads-api";

type SolsteadPageProps = {
  guestId: string;
  solsteadsId: string;
};

export function SolsteadPageComponent({
  guestId,
  solsteadsId,
}: SolsteadPageProps) {
  const router = useRouter();
  const solsteadQuery = useGetSolstead({ solsteadsId });
  const solstead = solsteadQuery ? (solsteadQuery.data as Solstead) : null;
  const guestSolsteadQuery = useGetSolstead(
    { solsteadsId: guestId },
    { enabled: !!guestId }
  );
  const guest = guestSolsteadQuery
    ? (guestSolsteadQuery.data as Solstead)
    : null;

  const isCitizenPage = /^[1-9][0]*-\d{1,4}$/.test(solsteadsId);
  const isGuestPage = /^[1-9][0]*-\d{1,4}$/.test(guestId);
  const isSolsteadPage = !isCitizenPage && !isGuestPage;

  useEffect(() => {
    if (!solstead) {
      router.replace("/directory");
    }
  });

  // Make sure if this is a guest page the the guest is actually a guest in the solstead
  useEffect(() => {
    if (
      isGuestPage &&
      Array.isArray(solstead.guests) &&
      !solstead.guests.find(
        (sg) => sg.guestToken.address === guest.token.address
      )
    ) {
      router.replace(`/${solsteadsId}`);
    }
  });

  if (!solstead) {
    return null;
  }

  return (
    <>
      <TwitterCardMetaTags
        card="summary_large_image"
        creator="@SolsteadsNFT"
        description="Solsteads Surreal Estate: a beautiful, shareable home for your NFTs."
        image={isGuestPage ? guest.image : solstead.bannerImage}
        site="@SolsteadsNFT"
        title={isGuestPage ? guest.name : solstead.name}
      />
      <Background color={"#efe4e1"}>
        <PageContainer>
          <Menu />
          <Box display="flex" flexDirection="column" alignItems="center">
            <a href={`/${solstead.id}`}>
              <Typography sx={{ marginTop: "5rem" }} variant="h3">
                {isGuestPage ? `${solstead.name} Guest` : solstead.name}
              </Typography>
            </a>
            <Box marginTop="3rem">
              <AddressImage solstead={isGuestPage ? guest : solstead} />
            </Box>
          </Box>
          {isSolsteadPage && (
            <Neighbors
              leftNeighbor={getLeftNeighbor(solstead.street.id, solstead.id)}
              rightNeighbor={getRightNeighbor(solstead.street.id, solstead.id)}
            />
          )}
          {(isSolsteadPage || isGuestPage) && (
            <>
              {isCommunityStead(solsteadsId) ? (
                <CommunitySteadTabs solstead={solstead} />
              ) : (
                <>
                  {isGuestPage ? (
                    <GalleryTabs solstead={guest} guests={solstead.guests} />
                  ) : (
                    <GalleryTabs solstead={solstead} guests={solstead.guests} />
                  )}
                </>
              )}
            </>
          )}
        </PageContainer>
      </Background>
    </>
  );
}

function isCommunityStead(solsteadId) {
  return ["69-ape-lane"].includes(solsteadId);
}

function getLeftNeighbor(streetId: string, addressId: string) {
  const street = STREETS_MAP[streetId];
  const addressIndex = street.addresses.indexOf(addressId);
  const leftNeighborAddressIdIndex =
    addressIndex == 0 ? street.addresses.length - 1 : addressIndex - 1;
  const leftNeighborAddressId = street.addresses[leftNeighborAddressIdIndex];
  return getSolsteadConstants(leftNeighborAddressId);
}

function getRightNeighbor(streetId: string, addressId: string) {
  const street = STREETS_MAP[streetId];
  const addressIndex = street.addresses.indexOf(addressId);
  const rightNeighborAddressIdIndex =
    addressIndex == street.addresses.length - 1 ? 0 : addressIndex + 1;
  const rightNeighborAddressId = street.addresses[rightNeighborAddressIdIndex];
  return getSolsteadConstants(rightNeighborAddressId);
}

const Tab = styled(TabUnstyled)`
  padding: 1rem 2rem;

  :hover {
    text-decoration: underline;
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {<Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

type GalleryTabsProps = {
  solstead: Solstead;
  guests: Guest[];
};

export function GalleryTabs({ solstead, guests }: GalleryTabsProps) {
  const [currentTab, setCurrentTab] = React.useState(0);
  const isGuestPage = /^[1-9][0]*-\d{1,4}$/.test(solstead.id);

  const handleTabChange = useCallback((event, newTab) => {
    setCurrentTab(newTab);
  }, []);

  const tabStyle = {
    cursor: "pointer",
  };
  const activeTabStyle = {
    ...tabStyle,
    textDecoration: "underline",
  };

  const guestsToShow = isGuestPage
    ? guests?.filter(
        (g) => g && g.guestToken.address !== solstead.token.address
      )
    : guests?.filter(
        (g) => !g || g.guestToken.address !== solstead.token.address
      );

  return (
    <TabsUnstyled value={currentTab} onChange={handleTabChange}>
      <TabsListUnstyled
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <Tab
          style={currentTab === 0 ? activeTabStyle : tabStyle}
          component={Typography}
          variant="h5"
        >
          {isGuestPage ? "Collection" : "Current Residents"}
        </Tab>
        <Tab
          style={currentTab === 1 ? activeTabStyle : tabStyle}
          component={Typography}
          variant="h5"
        >
          {solstead.name.startsWith("Citizen") ? "Housemates" : "Guests"}
        </Tab>
      </TabsListUnstyled>
      <TabPanel index={0} value={currentTab}>
        <Gallery solstead={solstead} />
      </TabPanel>
      <TabPanel index={1} value={currentTab}>
        <Housemates solstead={solstead} guests={guestsToShow} small={false} />
      </TabPanel>
    </TabsUnstyled>
  );
}

export function CommunitySteadTabs({ solstead }) {
  const [value, setValue] = React.useState(0);

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
  }, []);

  const tabStyle = {
    cursor: "pointer",
  };
  const activeTabStyle = {
    ...tabStyle,
    textDecoration: "underline",
  };

  return (
    <TabsUnstyled value={value} onChange={handleChange}>
      <TabsListUnstyled
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Tab
          style={value === 0 ? activeTabStyle : tabStyle}
          component={Typography}
          variant="h5"
        >
          Local Apes
        </Tab>
        <Tab
          style={value === 1 ? activeTabStyle : tabStyle}
          component={Typography}
          variant="h5"
        >
          Treasury
        </Tab>
      </TabsListUnstyled>
      <TabPanel index={0} value={value}>
        <Roster communityMembers={solstead?.communityMembers} />
      </TabPanel>
      <TabPanel index={1} value={value}>
        <Gallery solstead={solstead} />
      </TabPanel>
    </TabsUnstyled>
  );
}
