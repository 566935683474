import Head from "next/head";
import PropTypes from "prop-types";

export function TwitterCardMetaTags({
  card,
  creator,
  description,
  image,
  site,
  title,
}) {
  return (
    <Head>
      <meta key="twitter:card" name="twitter:card" content={card} />
      <meta key="twitter:creator" name="twitter:creator" content={creator} />
      <meta
        key="twitter:description"
        name="twitter:description"
        content={description}
      />
      <meta key="twitter:image" name="twitter:image" content={image} />
      <meta key="twitter:site" name="twitter:site" content={site} />
      <meta key="twitter:title" name="twitter:title" content={title} />
    </Head>
  );
}

TwitterCardMetaTags.propTypes = {
  card: PropTypes.oneOf(["summary", "summary_large_image", "app", "player"])
    .isRequired,
  creator: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  site: PropTypes.string,
  title: PropTypes.string.isRequired,
};
