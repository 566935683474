import Image from "next/image";
import { useState } from "react";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import { FramedImage } from "../gallery/FramedImage";
import { getFrameOrDefault } from "../../../constants/frames";
import { Grid } from "@mui/material";
import { Typography } from "../../../components/material/Typography";
import { getSolsteadConstants } from "../../../constants";
import { TextPill } from "../../common/TextPill";
import TwitterIcon from "../../../../public/images/icon_twitter.svg";
import cloudflareLoader from "../../../imageLoader";

export function Roster({ communityMembers }) {
  const [numOfMembersToDisplay, setNumOfMembersToDisplay] = useState(12);

  const loadMore = () => {
    setNumOfMembersToDisplay(numOfMembersToDisplay + 6);
  };

  return (
    <InfiniteScroll
      dataLength={numOfMembersToDisplay} //This is important field to render the next data
      next={loadMore}
      hasMore={numOfMembersToDisplay < communityMembers?.length}
      loader={<GalleryText>Loading...</GalleryText>}
    >
      <RosterContainer>
        {communityMembers?.length ? (
          communityMembers?.slice(0, numOfMembersToDisplay).map((member) => {
            return <Member member={member} />;
          })
        ) : (
          <GalleryText>Loading...</GalleryText>
        )}
      </RosterContainer>
    </InfiniteScroll>
  );
}

const RosterContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
`;

const GalleryText = styled.h3`
  font-family: abodeFont;
  font-size: 2rem;
  color: #0f1954;
  text-align: center;
`;

function Member({ member }) {
  const twitterLink = `https://twitter.com/${member?.twitterHandle}`;

  return (
    <div>
      <Grid
        container
        flexDirection="column"
        alignItems="center"
        sx={{ marginBottom: "1.75rem" }}
      >
        <Grid item>
          <a
            href={`/${member?.primarySolsteadId}`}
            target="_blank"
            rel="noreferrer"
          >
            <FramedImage
              imgURL={member?.memberToken?.metadata?.image}
              frame={getFrameOrDefault("rounded-yellow", "rounded-yellow")}
            />
          </a>
        </Grid>
        {member.twitterHandle && (
          <Grid item>
            <TextPill
              link={twitterLink}
              content={
                <Grid container flexDirection="row" spacing={1}>
                  <Grid item>
                    <Image
                      loader={cloudflareLoader}
                      src={TwitterIcon}
                      height="20"
                      width="20"
                      alt=""
                    />
                  </Grid>
                  <Grid item>{`@${member.twitterHandle}`}</Grid>
                </Grid>
              }
            />
          </Grid>
        )}
        <Grid item sx={{ marginTop: ".5rem" }}>
          <a
            href={`/${member?.primarySolsteadId}`}
            target="_blank"
            rel="noreferrer"
          >
            <Typography variant="h6">
              {getSolsteadConstants(member?.primarySolsteadId).name}
            </Typography>
          </a>
        </Grid>
      </Grid>
    </div>
  );
}
