import React from "react";
import styled from "styled-components";
import {
  MARKETPLACE_LINK_BUILDER_MAP,
  MARKETPLACE_NAME_MAP,
} from "../../../constants";

const ForSaleSectionContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  @media only screen and (max-width: 960px) {
    flex-direction: column;
    margin-bottom: 2rem;
  }
`;

const ForSaleHeaderTitle = styled.h2`
  color: #0f1954;
  font-family: juicyFont;
  font-size: 2rem;
  margin-right: 3rem;
  text-align: center;
  @media only screen and (max-width: 960px) {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
`;

const ForSalePill = styled.div`
  align-items: center;
  display: flex;
  color: #0f1954;
  border: 2px solid #0f1954;
  border-radius: 10px;
  font-family: abodeFontBold;
  padding: 0.6rem 1rem;
  :hover {
    cursor: pointer;
  }
`;

const BuyNowText = styled.span`
  height: 20px;
`;

type ForSaleSectionProps = {
  walletAddress: string;
  tokenAddress: string;
};

export const ForSaleSection = ({
  walletAddress,
  tokenAddress,
}: ForSaleSectionProps) => {
  return (
    <ForSaleSectionContainer>
      <ForSaleHeaderTitle>This Solstead Is For Sale</ForSaleHeaderTitle>
      <a
        href={MARKETPLACE_LINK_BUILDER_MAP[walletAddress](tokenAddress)}
        target="_blank"
        rel="noreferrer"
      >
        <ForSalePill>
          <BuyNowText>
            Buy it on {MARKETPLACE_NAME_MAP[walletAddress]}
          </BuyNowText>
        </ForSalePill>
      </a>
    </ForSaleSectionContainer>
  );
};
