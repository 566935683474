import React, { useMemo, useState } from "react";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import { Artwork } from "./Artwork";
import { Solstead } from "../../../../solsteads-api";
import { sortTokens } from "../../../backend/application";
import {
  generateTokenFilterMap,
  generateTokenStringIdentifier,
} from "../../../utils";

const GalleryWallContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
`;

const GalleryText = styled.h3`
  font-family: abodeFont;
  font-size: 2rem;
  color: #0f1954;
  text-align: center;
`;

type GalleryWallProps = {
  solstead: Solstead;
};

export const GalleryWall = ({ solstead }: GalleryWallProps) => {
  const [numOfTokensToDisplay, setNumOfTokensToDisplay] = useState(12);

  const loadMore = () => {
    setNumOfTokensToDisplay(numOfTokensToDisplay + 6);
  };

  const hiddenTokensMap = useMemo<{ [key: string]: boolean }>(
    () => generateTokenFilterMap(solstead?.gallery?.filter),
    [solstead?.gallery?.filter]
  );

  const tokensToDisplay = solstead?.gallery?.tokens.filter((t) => {
    return !hiddenTokensMap[generateTokenStringIdentifier(t)];
  });

  return (
    <InfiniteScroll
      dataLength={numOfTokensToDisplay} //This is important field to render the next data
      next={loadMore}
      hasMore={numOfTokensToDisplay < tokensToDisplay?.length}
      loader={<GalleryText>Loading...</GalleryText>}
    >
      <GalleryWallContainer>
        {tokensToDisplay?.length ? (
          tokensToDisplay.slice(0, numOfTokensToDisplay).map((token) => {
            return (
              <Artwork
                key={`${token.address}/${token.id}`}
                token={token}
                solstead={solstead}
              />
            );
          })
        ) : (
          <GalleryText>This home is unoccupied.</GalleryText>
        )}
      </GalleryWallContainer>
    </InfiniteScroll>
  );
};
