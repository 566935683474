import Image from "next/image";
import React from "react";
import styled from "styled-components";
import { Metadata, Solstead, Token } from "../../../../solsteads-api";
import { getFrameOrDefault } from "../../../constants/frames";
import { useGetTokenMetadata } from "../../../hooks";
import { NFTImage } from "./NFTImage";
import Popover from "@mui/material/Popover";

import Modal from "@mui/material/Modal";
import { Button, Paper, Typography } from "../../material";
import { Grid, Hidden } from "@mui/material";
import { useMediaQuery } from "@react-hook/media-query";
import cloudflareLoader from "../../../imageLoader";

const ArtworkContainer = styled.div`
  margin: 0.2rem;
  position: relative;
`;

type ArtworkProps = {
  token: Token;
  solstead: Solstead;
};

export function Artwork({ token, solstead }: ArtworkProps) {
  const [aspectRatio, setAspectRatio] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const isSmallScreen = useMediaQuery("only screen and (max-width: 960px)");

  const frame = getFrameOrDefault(
    solstead.gallery.frame.id,
    solstead.defaultFrame.id
  );

  const onImageLoad = (img) => {
    const aRatio = img.offsetWidth / img.offsetHeight;
    setAspectRatio(aRatio);
  };

  const { isLoading, error, data } = useGetTokenMetadata(
    {
      tokenAddress: token.address,
    },
    {
      enabled: !token.metadata,
    }
  );

  if (isLoading && !token.metadata) {
    return <></>;
  }

  if (error && !token.metadata) {
    return <></>;
  }

  if (!data && !token.metadata) {
    return <></>;
  }

  const metadata = token.metadata ? token.metadata : (data as Metadata);

  if (!metadata.image) {
    return <></>;
  }

  // TODO this logic isn't right.  This needs to be like a percentage of the difference in ratio from 1.
  // This does however seem to work for some.  So idk maybe it's partially right?
  const isAspectRatioNearOne = 0.7 < aspectRatio && aspectRatio < 1.2;
  const framePaddingString = frame
    ? `${frame.padding.y}px ${frame.padding.x * aspectRatio}px`
    : "1rem";

  return (
    <ArtworkContainer style={{ padding: framePaddingString }}>
      <div style={{ cursor: "pointer" }} onClick={handleOpen}>
        <NFTImage onLoad={onImageLoad} src={metadata.image} alt="NFT Image" />
        {frame && isAspectRatioNearOne ? (
          <Image
            loader={cloudflareLoader}
            alt=""
            src={`/frames/${frame.imageFilename}`}
            layout={"fill"}
          />
        ) : null}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          sx={{
            width: "calc(100vw - 4rem)",
            maxWidth: "550px",
            maxHeight: "calc(90vh - 4rem)",
            overflowY: "auto",
            padding: isSmallScreen ? "1rem" : "2rem 3rem",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Grid
            container
            flexDirection="column"
            alignItems="flex-start"
            spacing={2}
            flexWrap="nowrap"
          >
            <Grid item alignSelf="center">
              <Typography
                id="modal-modal-title"
                variant="h3"
                sx={{ fontSize: "2rem" }}
              >
                {metadata?.name}
              </Typography>
            </Grid>
            <Grid item alignSelf="center">
              <img src={metadata.image} alt={metadata.name} />
            </Grid>
            <Grid item>
              {metadata?.collection?.family || metadata?.collection?.name ? (
                <Typography id="modal-modal-title" variant="h4">
                  {getCollectionDisplayName(metadata)}
                </Typography>
              ) : null}
            </Grid>

            <Grid item>
              <Typography id="modal-modal-description" variant="body1">
                {metadata?.description}
              </Typography>
            </Grid>
            <Grid item>
              {metadata?.attributes?.length ? (
                <>
                  <Typography variant="h6" sx={{ marginBottom: ".5rem" }}>
                    Attributes
                  </Typography>
                  {metadata.attributes.map((attribute, index) => (
                    <AttributePill
                      key={index}
                      value={attribute.value}
                      traitType={attribute.trait_type}
                      isLast={index === metadata.attributes.length - 1}
                    />
                  ))}
                </>
              ) : null}
            </Grid>
            {token.blockchain === "sol" && (
              <Grid item alignSelf="center" sx={{ marginTop: "1rem" }}>
                <a
                  href={`https://exchange.art/single/${token.address}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button variant="outlined">
                    Make an offer on Exchange.Art
                  </Button>
                </a>
              </Grid>
            )}
            {token.blockchain === "eth" && (
              <Grid item alignSelf="center" sx={{ marginTop: "1rem" }}>
                <a
                  href={`https://opensea.io/assets/${token.address}/${token.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button variant="outlined">Make an offer on OpenSea</Button>
                </a>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Modal>
    </ArtworkContainer>
  );
}

function getCollectionDisplayName(metadata: Metadata) {
  const family = metadata?.collection?.family;
  const name = metadata?.collection?.name;

  if (family && name) {
    if (family === name) {
      return name;
    } else {
      return `${family}: ${name}`;
    }
  }
  if (name) {
    return name;
  }
  if (family) {
    return family;
  }
  return "";
}

function AttributePill({ value, traitType, isLast }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (["none", "None", "null"].includes(value)) {
    return null;
  }

  return (
    <>
      <div
        style={{
          cursor: "default",
          display: "inline-block",
          paddingRight: ".5rem",
          paddingBottom: ".25rem",
        }}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {value}
        {isLast ? "" : ","}
      </div>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ padding: ".5rem" }}>
          {traitType}: {value}
        </Typography>
      </Popover>
    </>
  );
}
