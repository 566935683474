import React from "react";
import styled from "styled-components";
import { Solstead } from "../../../solsteads-api";

import { LeftNeighborArrow } from "./NeighborArrowLeft";
import { RightNeighborArrow } from "./NeighborArrowRight";

const NeighborContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2rem auto 4rem auto;
  width: 100%;
  max-width: 1100px;

  @media only screen and (max-width: 960px) {
    flex-direction: column;
  }
`;

type NeighborsProps = {
  leftNeighbor: Partial<Solstead>;
  rightNeighbor: Partial<Solstead>;
};

export const Neighbors = ({
  leftNeighbor,
  rightNeighbor,
}: NeighborsProps): JSX.Element => {
  return (
    <NeighborContainer>
      <LeftNeighborArrow solstead={leftNeighbor} />
      <RightNeighborArrow solstead={rightNeighbor} />
    </NeighborContainer>
  );
};
